import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
//import Layout from '../views/Layout.vue'
//import About from '../views/About.vue'
//import Clients from '../views/Clients.vue'
//import Contact from '../views/Contact.vue'
//import Projet from '../views/Projet.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  /*{
    path: '/',
    component: Layout,
    name: 'home',
    children: [
      {
        path: 'about',
        name: 'about',
        component: About
      },
      {
        path: 'clients',
        name: 'clients',
        component: Clients
      },
      {
        path: 'contact',
        name: 'contact',
        component: Contact
      },
      {
        path: ':projet',
        name: 'projet',
        component: Projet
      }
    ]
  }*/
  {
    path: '/',
    component: Home,
    name: 'home'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

/*router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) next('/login')
  // else if (!requiresAuth && currentUser) next('/')
  else next()
})*/

export default router
