<template>
  <div class="loader-page">
    <div class="loader-wrapper">
      <div class="loader-inner">
        <div><img :src="require('../../assets/puff.svg')" /></div>
      </div>
    </div>
  </div>
</template>

<script>

import { gsap } from 'gsap'

export default {
  name: 'loader',
  props: ['loader_anim'],
  components: {
  },
  data() {
    return {
      created_time: new Date()
    }
  },
  watch: {
    loader_anim: function(newVal, oldVal) {
      if(newVal !== oldVal) this.closeLoader()
    }
  },
  computed: {
  },
  methods: {
    closeLoader() {
      let self = this
      let ended_time = new Date()
      var dif = ended_time.getTime() - this.created_time.getTime()
      var dif_seconds = dif / 1000
      let timeOut = dif_seconds < 2 ? 2 : 0
      setTimeout(function() {
        gsap.timeline({onComplete:function(){
          self.$emit('close_loader')
        }})
        .to('.loader-inner', {opacity:0, duration:0.7})
        .to('.loader-page', {opacity:0, duration:0.7})
      }, timeOut*1000)
    }
  }
}
</script>
<style lang="scss" scoped>

.loader-page {
  position:fixed;
  top:0px;
  left:0px;
  width:100vw;
  height:100vh;
  background-color: #222222;
  z-index:9999;
  .loader-wrapper {
    position: relative;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    .loader-inner {
      position: relative;
      -webkit-opacity: 1;
      opacity: 1;
    }
  }
}

</style>