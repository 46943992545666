import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    firebaseConfig: {
      apiKey: "AIzaSyB6zh_HPZZWeR_fWuyDlJbC7-kkeP51egs",
      authDomain: "crazymage-website.firebaseapp.com",
      databaseURL: "https://crazymage-website.firebaseio.com",
      projectId: "crazymage-website",
      storageBucket: "crazymage-website.appspot.com",
      messagingSenderId: "1031989479680",
      appId: "1:1031989479680:web:d64484a2f7d2c1c9a68be5",
      measurementId: "G-ZLMPZWQMDH"
    },
    clients: [],
    works: [],
  },
  mutations: {
    increment (state) {
      state.count++
    }
  },
  methods: {
    getWorks() {
      return this.works
    }
  }
})

export default store