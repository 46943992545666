<template>
  <div>
    <Loader v-if="loader_active" v-on:close_loader="loader_active = false"  :loader_anim="data_loaded" />
    <div>
      <router-view v-on:init="onInit()" v-bind:loader_active="loader_active"></router-view>
    </div>
  </div>
</template>

<script>
/*<Menu v-on:openPageFromMenu="openPageFromMenu" v-show="activeMenu" />
      <BtnBurger :activeBtn="activeBtn" v-on:toggleBtn="toggleBtn" />
      <router-view></router-view> */
//import BtnBurger from '@/components/home/BtnBurger'
//import Menu from '@/components/home/Menu'
import { initializeApp } from "firebase/app"
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore"
import Loader from '@/components/home/Loader'

export default {
  name: 'App',
  components: {
    //BtnBurger,
    //Menu,
    Loader
  },
  data() {
    return {
      db: null,
      db_works: false,
      db_clients: false,
      loader_active: true,
      init: false,


      activeMenu: false,
      activeBtn: this.$route.name !== 'home',
      initAssets: false
      
    }    
  },
  created () {
    this.connectToFirestore()
  },
  beforeDestroy () {
  },
  computed: {
    data_loaded() {
      return this.db_works && this.db_clients && this.init
    }
  },
  methods: {
    /* FIRESTORE */
    connectToFirestore() {
      console.log('connectToFirestore')
      let firebaseConfig = this.$store.state.firebaseConfig
      const firebaseApp = initializeApp(firebaseConfig)
      this.db = getFirestore(firebaseApp)
      this.getWorks()
      this.getClients()
    },
    async getWorks() {
      const q = query(collection(this.db, "works"), where("masquer", "==", false))
      const querySnapshot = await getDocs(q)
      let works = []
      querySnapshot.forEach((doc) => {
        works.push(doc.data())
      })
      this.$store.state.works = works
      this.db_works = true
      //console.log('works est recupere')
    },
    async getClients() {
      const q = query(collection(this.db, "clients"))
      const querySnapshot = await getDocs(q)
      let clients = []
      querySnapshot.forEach((doc) => {
        clients.push(doc.data())
      })
      this.$store.state.clients = clients
      this.db_clients = true
      //console.log('clients est recupere')
    },
    onInit() {
      this.init = true
      console.log('INITINITNITN !!!!')
    },



    /* TO CHECK */
    openPage(data) {
      let page = data.name
      let params = data.projet ? data.projet : null
      if (!page || page == this.$route.name) return
      else {
        this.activeBtn = !this.activeBtn
        this.$router.push({ name: page, params: {projet: params} })
      }
    },
    openPageFromMenu(page) {
      this.$router.push({ name: page })
      let self = this
      setTimeout(function() {
        self.activeMenu = !self.activeMenu
      }, 1000)
    },
    openMenu() {
      this.toggleBtn()
    },
    toggleBtn() {
      if(this.$route.name == 'home') {
        this.activeBtn = !this.activeBtn
        this.activeMenu = !this.activeMenu
      } else {
        if(this.activeBtn) this.openPage({name:'home'})
      }      
    }
  }
};
</script>

<style lang="scss">

/*@font-face {
    font-family: 'SourceSansProExtraLight';
    src: url(assets/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf);
}*/
@font-face {
    font-family: 'SourceSansProLight';
    src: url(assets/fonts/SourceSansPro/SourceSansPro-Light.ttf);
}
/*@font-face {
    font-family: 'SourceSansProRegular';
    src: url(assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf);
}
@font-face {
    font-family: 'SourceSansProBold';
    src: url(assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf);
}*/
/*.sourceSansProExtraLight {
  font-family: 'SourceSansProExtraLight' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}*/
.sourceSansProLight {
  font-family: 'SourceSansProLight' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Austin';
  src: url(assets/fonts/Austin/AustinLightItalic.ttf);
}
.austin {
  font-family: 'Austin', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url(assets/fonts/FoundersGrotesk/FoundersGroteskXCond-SmBd.eot);
  src: url(assets/fonts/FoundersGrotesk/FoundersGroteskXCond-SmBd.eot) format("embedded-opentype"),url(assets/fonts/FoundersGrotesk/FoundersGroteskXCond-SmBd.woff) format("woff"),url(assets/fonts/FoundersGrotesk/FoundersGroteskXCond-SmBd.ttf) format("truetype"),url(assets/fonts/FoundersGrotesk/FoundersGroteskXCond-SmBd.svg#FoundersGrotesk) format("svg");
}
.founder {
font-family: 'FoundersGrotesk' !important;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Vidaloka';
  src: url(assets/fonts/Vidaloka/Vidaloka-Regular.ttf);
}
.vidaloka {
  font-family: 'Vidaloka', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  padding: 0;
  margin: 0;
}



</style>