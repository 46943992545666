import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

//import firebase from 'firebase/app'
//import 'firebase/auth'
//import firebase from 'firebase/app'
//import firebase from 'firebase/compat/app'
//import 'firebase/compat/auth'

Vue.config.productionTip = false
//Vue.mixin(mixins)

//let app = ''

/*const firebaseConfig = {
  apiKey: "AIzaSyB6zh_HPZZWeR_fWuyDlJbC7-kkeP51egs",
  authDomain: "crazymage-website.firebaseapp.com",
  databaseURL: "https://crazymage-website.firebaseio.com",
  projectId: "crazymage-website",
  storageBucket: "crazymage-website.appspot.com",
  messagingSenderId: "1031989479680",
  appId: "1:1031989479680:web:d64484a2f7d2c1c9a68be5",
  measurementId: "G-ZLMPZWQMDH"
}

firebase.initializeApp(firebaseConfig)*/

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/*firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})*/