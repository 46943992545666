<template>
  <div :class="'work-item' + ' work-item-' + display">
    <a
      :class="'work-item-link' + classAHover"
      @click="openWork"
      v-on:mouseover="mouseover"
      v-on:mouseout="mouseleave"
    >
      <div class="work-item-image">
        <div class="work-item-image-ratio" :style="paddingBottom ? 'padding-bottom:' + paddingBottom : ''"></div>
        <div class="work-item-image-content">
          <img :class="'work-item-image-content-img' + classItemImage" @load="imageLoaded" :src="srcImg"/>
        </div>
        <div class="work-item-video-content">
          <video :class="classItemVideo" :ref="'video-'+display" @canplay="onCanPlay" loop muted :src="srcVideo"></video>
        </div>
        <div :class="'work-item-image-overlay' + classItemImageOverlay"></div>
      </div>
      <div class="work-item-title">
        <div class="libelle austin">{{libelle}}</div>
        <div class="category sourceSansProLight">{{category}}</div>
        <div class="client sourceSansProLight">{{client}}</div>
      </div>
    </a>
  </div>
</template>

<script>

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'home-video',
  components: {
  },
  props: ['index', 'doc', 'bigVideoLoaded', 'firstInit', 'forceToLoad', 'currentVideoPlayed'],
  data() {
    return {

      imageActive: false,
      waitOneSecForReveal: true,
      isImageLoaded: false,
      isVideoLoaded: false,
      isAnimRevealEnd: false,
      srcVideo: '',
      overlay: false,
      player: null,
      isPlayerVisible: false,


      /*video_id: this.doc.video_id ? this.doc.video_id : null,
      video_local: this.doc.video_local ? this.doc.video_local : null,
      loadLocalVideo: null,
      playerReady: false,
      player: null,
      startTime: this.doc.startTime ? this.doc.startTime : null,
      endTime: this.doc.endTime ? this.doc.endTime : null,
      videoLoading: false,
      play: false,
      isPhone: false,
      observer: null,
      intersected: false,
      image: this.doc.image ? this.doc.image : null,
      picture: 'https://picsum.photos/800/400?random=' + this.index*/
    }
  },
  mounted() {
    /* if(this.forceToLoad) {
      // console.log(this.forceToLoad)
      // this.$emit('videoToLoaded', this.video_id)
      if(this.video_id) this.loadVimeo()
    } */
    this.startScrollAnimation()
  },
  destroyed() {
    // this.observer.disconnect();
  },
  computed: {
    srcImg() {
      //return this.image ? require('../../assets/works/min/' + this.image + '-min.jpg') : this.picture
      //return this.image ? this.image : this.picture
      return this.imageActive ? this.doc.image : ''
    },
    display() {
      return this.doc.display
    },
    libelle() {
      return this.doc.libelle
    },
    client() {
      return this.doc.client.toUpperCase()
    },
    category() {
      return this.doc.category.toUpperCase()
    },
    paddingBottom() {
      return this.doc.paddingBottom
    },
    description() {
      return this.doc.description ? this.doc.description : ''
    },
    classItemVideo() {
      return this.isPlayerVisible ? ' active' : ''
    },
    classItemImage() {
      return this.isImageLoaded && !this.waitOneSecForReveal ? ' work-item-image-content-img-anim' : ''
    },
    classItemImageOverlay() {
      if(this.imageActive && this.isImageLoaded && !this.waitOneSecForReveal) return ' work-item-image-overlay-anim work-item-image-overlay-anim-out'
      else if (this.imageActive) return ' work-item-image-overlay-anim'
      else return ''
    },
    classAHover() {
      return this.isAnimRevealEnd ? ' work-item-link-hover' : ''
    }
    
    
  },
  watch: {
    'bigVideoLoaded': function() {
      // if(this.bigVideoLoaded) this.initObserver();
    },
    'currentVideoPlayed': function() {
      if(this.currentVideoPlayed !== this.doc.id) this.stopVideo()
    }
  },
  methods: {
    startScrollAnimation() {
      let self = this
      gsap.timeline({
        scrollTrigger: {
          trigger: ".work-item-"+self.display,
          start: "top 80%",
          end: "center",
          markers: false,
          scrub: false,
          pin: false,
          onEnter: function() {
            self.imageActive = true
            setTimeout(function() {
              self.waitOneSecForReveal = false              
            }, 1000)
          }
        }
      })     
    },
    imageLoaded() {
      let self = this
      this.isImageLoaded = true
      setTimeout(function() {
        self.isAnimRevealEnd = true
      }, 1500)
    },
    openWork() {
      console.log(this.display);
    },
    mouseover() {
      //let self = this
      this.overlay = true
      /*if(this.srcVideo == '') {
        if(!this.isAnimRevealEnd) {
          setTimeout(function(){self.srcVideo = self.doc.video_local}, 500)
        } else {
          this.srcVideo = this.doc.video_local
        }
      }*/
      if(this.srcVideo == '' && this.isAnimRevealEnd) {
        this.srcVideo = this.doc.video_local
      }
      if(this.isVideoLoaded && this.player && this.player.paused) {
        this.isPlayerVisible = true
        this.player.play()
      }
    },
    mouseleave() {
      this.overlay = false
      if(this.isVideoLoaded && this.player && !this.player.paused) {
        this.isPlayerVisible = false
        this.player.pause()
      }
    },
    onCanPlay() {
      if(!this.isVideoLoaded) this.isVideoLoaded = true
      this.player = this.$refs['video-'+this.doc.display]
      if(this.overlay) {
        this.isPlayerVisible = true
        this.player.play()
      }
    },





    onCanPlay_old() {
      this.playerReady = true
      this.videoLoading = false
      this.player = this.$refs.thumbnailvideo
      if(!this.overlay) this.player.play()
    },
    /*loadVimeo() {
      this.videoLoading = true
      let self = this
      this.player = new Player( this.$refs.videoPlayer , {
        id: self.video_id,
        controls: false,
        dnt: true,
        loop: false,
        muted: true,
        title: false,
        autoplay: true,
        quality:'720p'
      })
      this.player.ready().then(function() {
        if(self.firstInit) self.$emit('videoLoaded', self.video_id)
        self.playerReady = true
        self.videoLoading = false
        if(self.startTime) self.player.setCurrentTime(self.startTime)
        if(!self.overlay && !self.play) {
          self.play = true
          self.player.play()
        } else {
          self.play = false
          self.player.pause()
        }
      })
      this.player.on('timeupdate', function(t) {
        let currTime = t.seconds
        if((self.endTime && currTime > self.endTime) || (self.startTime && currTime < self.startTime)) self.player.setCurrentTime(self.startTime)
      })
      this.player.on('play', function() {
        self.$emit('onPlay', self.doc.id)
      })
      this.player.on('pause', function() {
      })
    },*/
    stopVideo() {
      let self = this
      if(!this.video_local) {
        setTimeout(function() {
          if(self.playerReady && self.player && self.play) {
            self.play = false
            self.player.pause()
          }
        }, 200)
      } else {
        if(this.playerReady && this.player && this.play) {
          self.play = false
          self.player.pause()
        }
      }
      
    },
    mouseover_old() {
      let self = this
      this.overlay = false      
      setTimeout(function() {
        if(!self.overlay) {
          if(!self.player && !self.video_local) {
            self.loadVimeo()
          }
          if(!self.player && self.video_local) {
            self.videoLoading = true
            self.loadLocalVideo = true
            // self.loadVideoLocal()
          }
          if(self.playerReady && self.player && !self.play) {
            self.play = true
            self.player.play()
            self.$emit('onPlay', self.doc.id)
          }
        }        
      }, 300)      
    },
    mouseleave_old() {
      this.overlay = true
      this.stopVideo()
    },
    /*imgLoaded() {
      this.imageloaded = true
    },*/
    openPage(page) {
      this.$emit('openPage', {name:page, projet:this.doc.display})
    } 
  }
}
</script>
<style lang="scss">

  .work-item {
    padding-top: 1em;
    padding-bottom: 1.5em;
    flex-basis: calc(100vw - 4em);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: all 0.6s;
    position: relative;
    &:hover {
      opacity: 1 !important;
      -webkit-filter: grayscale(0%) !important;
      -moz-filter: grayscale(0%) !important;
      filter: grayscale(0%) !important;
      //transition: all 0.5s ease;
    }

    .work-item-image {
      //background-color: #f9f9f9;
      position: relative;
      overflow: hidden;
      flex: 1 0 auto;
      display: flex;
      max-width: 100%;
      transition: box-shadow .5s ease;

      .work-item-image-ratio {
        //padding-bottom: 45.4545%;
        padding-bottom: 70%;
      }
      .work-item-video-content {
        z-index: 2;
        //display: block;
        height: 100%;
        //font-family: "object-fit:cover;object-position:center";
        //object-fit: cover;
        //object-position: center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //transform: translate(-50%, -50%);
        video {
          opacity: 0;
          transition: opacity 0.6s;
          //display: block;
          //height: 100%;
          //font-family: "object-fit:cover;object-position:center";
          //object-fit: cover;
          //object-position: center;
          //width: 100%;
          //will-change: all;
          //height: 103%;
          //width: 103%;
          position: absolute;
          top: 50%;
          left: 50%;

          width: 100%;
          height: 56.25%;
          min-height: 110%;
          min-width: 194%;
          transform: translate(-50%, -50%);
          &.active {
            opacity: 1;
          }
        }
      }
      .work-item-image-content {
        //display: none;
        z-index: 1;
        transform: scale(1);
        //display: block;
        height: 102%;
        font-family: "object-fit:cover;object-position:center";
        object-fit: cover;
        object-position: center;
        width: 102%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .work-item-image-content-img {
          display: block;
          height: 100%;
          font-family: "object-fit:cover;object-position:center";
          object-fit: cover;
          object-position: center;
          width: 100%;
          opacity: 0;
          will-change: all;
          &.work-item-image-content-img-anim {
            /*animation: imageReveal 2s ease forwards running;
            @keyframes imageReveal {
              0% {
                opacity: 0;
                transform: scale(1);
              }
              60% {
                opacity: 0;
                transform: scale(1);
              }
              61% {
                opacity: 1;
                transform: scale(1.1);
              }
              100% {
                transform: scale(1);
                opacity: 1;
              }
            }*/
            animation: imageReveal 1s ease forwards running;
            @keyframes imageReveal {
              0% {
                opacity: 0;
                transform: scale(1);
              }
              1% {
                opacity: 1;
                transform: scale(1.1);
              }
              100% {
                transform: scale(1);
                opacity: 1;
              }
            }
          }
        }
      }
      .work-item-image-overlay {
        position: absolute;
        background-color: #eee;
        height: 200%;
        width: 110%;
        left: -110%;
        top: 100%;
        will-change: transform;
        z-index: 2;
        &.work-item-image-overlay-anim {
          //animation: imageOverlayReveal 2s ease forwards running;
          /*@keyframes imageOverlayReveal {
            0% {
              transform: translate(40%, 0);
            }
            55% {
              transform: translate(100%, -100%);
            }
            60% {
              transform: translate(100%, -100%);
            }
            100% {
              transform: translate(100%, 10%);
            }
          }*/
          animation: imageOverlayReveal 1s ease forwards running;
          @keyframes imageOverlayReveal {
            0% {
              transform: translate(40%, 0);
            }
            100% {
              transform: translate(100%, -100%);
            }
          }
        }
        &.work-item-image-overlay-anim-out {
          animation: imageOverlayRevealOut 1s ease forwards running;
          @keyframes imageOverlayRevealOut {
            0% {
              transform: translate(100%, -100%);
            }
            100% {
              transform: translate(100%, 10%);
            }
          }
        }
      }
    }
    .work-item-title {
      transition: all 0.8s;
      .libelle {
        padding-top: 0.5em;
        font-size: 1.8em;
        color: #141414;
      }
      .category {
        padding-top: 0.3em;
        font-size: 0.8em;
        color: #000;
        letter-spacing: 0.05em;
      }
      .client {
        font-size: 0.8em;
        color: #000;
        letter-spacing: 0.05em;
      }
      .category, .client {
        color: #141414;
      }
    }

    .work-item-link {
      cursor: pointer;
      .work-item-image {
        animation: workingItemReverse .525s ease-out forwards running;
      }
    }
    .work-item-link.work-item-link-hover {
      &:hover {
        //opacity: 1 !important;
        .work-item-image {
          -webkit-backface-visibility: hidden;
          animation: workingItemHover .525s ease-in forwards running;
          box-shadow: 0 0 10px rgba(27, 27, 29, .3);
        }
        .work-item-title {
          transform: translateY(0.3em)
        }
      }
    }
    @keyframes workingItemHover {
      0% {
        -webkit-transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(0);
        transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(0);
      }
      50% {
        -webkit-transform: perspective(1600px) rotateX(3deg) rotateY(3deg) translateZ(15px);
        transform: perspective(1600px) rotateX(3deg) rotateY(3deg) translateZ(15px);
      }
      100% {
        -webkit-transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(30px);
        transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(30px);
      }
    }
    @keyframes workingItemReverse {
      0% {
        transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(30px);
      }
      50% {
        transform: perspective(1600px) rotateX(-3deg) rotateY(-3deg) translateZ(15px);
      }
      100% {
        transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(0);
      }
    }
    /*@keyframes workingItemHover {
      0% {
        -webkit-transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(0);
        transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(0);
      }
      50% {
        -webkit-transform: perspective(1600px) rotateX(5deg) rotateY(5deg) translateZ(50px);
        transform: perspective(1600px) rotateX(5deg) rotateY(5deg) translateZ(50px);
      }
      100% {
        -webkit-transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(100px);
        transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(100px);
      }
    }
    @keyframes workingItemReverse {
      0% {
        transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(100px);
      }
      50% {
        transform: perspective(1600px) rotateX(-5deg) rotateY(-5deg) translateZ(50px);
      }
      100% {
        transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(0);
      }
    }*/

    /*&:hover .work-item-link {
      opacity: 0.1;
    }*/
   /*&:hover .work-item-link .work-item-image-content-img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }*/
    

    @media (min-width: 42em) {

    }

    @media (min-width: 60em) {
      flex-basis: calc(50vw - 2em - 5em);
    }

    @media (min-width: 72em) {
      flex-basis: calc(33.33vw - 2em - 6em/2);
    }

    @media (min-width: 110em) {
      flex-basis: calc(33.33vw - 2em - 6em/2);
    }

  }

</style>